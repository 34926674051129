import { Amplify } from 'aws-amplify';

// ********* AWS Configs ******************** //

import { configDev } from '../aws-config-dev';

if (process.env.NODE_ENV === 'development') {
  //console.log("MODE IS: STAGING");
  Amplify.configure(configDev);
}

import { configProd } from '../aws-config-prod';

if (process.env.NODE_ENV === 'production') {
  //console.log("MODE IS: PRODUCTION");
  Amplify.configure(configProd);
}

// ********* Amplify Imports ******************** //

import { fetchAuthSession, getCurrentUser} from 'aws-amplify/auth';

// *************************** Import CSS *************************** //

import "./nav-header-footer.css";

// ************************** Import Images ************************ //

import "../assets/menther-logos/menther-mark-white-pink-tagline.png";
import "../assets/menther-logos/menther-mark-white-pink.png";
import "../assets/icons/social/instagram.svg";
// import "../assets/icons/social/instagram-hover.svg";
import "../assets/icons/social/linkedin.svg";
// import "../assets/icons/social/linkedin-hover.svg";
import "../assets/icons/social/facebook.svg";
// import "../assets/icons/social/facebook-hover.svg";

// ***** Constants *********** //

let currentUser = null;
let currentUserInfo = null;

// *************************************************** Insert 1st Top Navigation Menu **************************************************** //

var profileName = sessionStorage.getItem("displayName");

class FirstNav extends HTMLElement {
  connectedCallback() {
    this.innerHTML = `

    <div class="container">

      <!-- BEGIN: First Navigation Bar -->

      <nav id="first-nav" class="navbar navbar-expand-lg fixed-top bg-menther-wine" aria-label="Navigation">

       <div class="container">

          <!-- Menther Logo -->

          <a class="navbar-brand" href="./index.html">
            <img src="menther-mark-white-pink-tagline.png" class="navbar-wordmark d-inline-block align-top mt-2" 
            alt="Menther. You are more powerful than you think." />
            <span class="hidden">Menther</span>
          </a>

          <!-- Toggle Button for Mobile Nav -->

          <button class="navbar-toggler collapsed d-flex d-lg-none flex-column justify-content-around"
            type="button" data-bs-toggle="collapse" data-bs-target="#navbar-items"
            aria-controls="navbar-items" aria-expanded="false" aria-label="Toggle navigation">
            <span class="toggler-icon top-bar"></span>
            <span class="toggler-icon middle-bar"></span>
            <span class="toggler-icon bottom-bar"></span>
          </button>

          <!-- BEGIN: Navigation Menu -->

          <div id="navbar-items" class="collapse navbar-collapse">

          <!-- BEGIN: Nav Bar Buttons & Items -->

            <ul id="first-nav-buttons" class="navbar-nav ms-auto text-center mb-2 mb-sm-0">

            <!-- HOME BUTTON -->
              <li id="home-nav-button" class="nav-item mx-auto mx-lg-1 mt-1">
                <a href="index.html" class="nav-link bold btn btn-primary">HOME</a>
              </li>

            <!-- SIGN UP BUTTON -->
              <li id="signup-nav-button" class="nav-item mx-auto mx-lg-1 mt-1">
                <a href="signup.html" class="nav-link bold btn btn-primary">SIGN UP</a>
              </li>

            <!-- LOG IN BUTTON -->
              <li id="login-nav-button" class="nav-item mx-auto me-lg-3 mt-1">
                <a href="login.html" class="nav-link bold btn btn-primary">LOG IN</a>
              </li>

            </ul>

          <!-- END: Nav Bar Buttons & Items -->

          </div>

          <!-- END: Navigation Menu -->

        </div> <!-- container -->

      </nav>

    <!-- END: First Navigation Bar -->

    </div> <!-- container -->

    `
  }
}

// ************************************************* Insert Footer ***************************************** //

class Footer extends HTMLElement {
  connectedCallback() {
    this.innerHTML = `

  <div class="container px-1">

  <!-- BEGIN: Main Footer Links -->
    
    <section>

      <div class="row d-flex justify-content-center">

      <!-- Menther -->

        <div class="col-lg-4 col-md-6 mb-2 mb-md-0 px-sm-1 px-md-0 px-lg-0">

          <a href="./index.html">
            <img class="footer-logo-img" height="30px" src="menther-mark-white-pink.png" alt="Menther wordmark">
          </a>

          <!-- <p class="footer-tagline pt-2 text-left">YOU ARE MORE POWERFUL THAN YOU THINK</p> -->
          
          <p class="text-center ps-lg-3 pt-3">An online community and platform that allows women of all ages, backgrounds, 
          life stages, and industries to find and engage in meaningful female mentorship.</p>

        </div>
    
    <!-- BEGIN: Social Media & Copyright -->

      <div class="text-center px-3">

        <!-- BEGIN: Social media -->
        <section class="my-0 d-flex justify-content-center">

        <!-- Social Media Icons -->

          <ul id="social-media-icons" class="navbar-nav col-lg-4 d-flex justify-content-center">
            <li class="social-item-1"><a class="social-link m-md-2 m-sm-1" href="https://www.instagram.com/mentherpower" target="_blank"><img src="instagram.svg" width="30" height="30" class="img"></a></li>
            <li class="social-item-2"><a class="social-link m-md-2 m-sm-1" href="https://www.linkedin.com/company/menther" target="_blank"><img src="linkedin.svg" width="30" height="30" class="img"></a></li>
            <li class="social-item-3"><a class="social-link m-md-2 m-sm-1" href="https://twitter.com/menther" target="_blank"><img src="twitter.svg" width="30" height="30" class="img"></a></li>
          <!--  <li class="social-item-4"><a class="social-link m-md-2 m-sm-1" href="https://www.facebook.com/mlnp.tv" target="_blank"><img src="facebook.svg" width="30" height="30" class="img"></a></li> -->
          </ul>

        </section>
        <!-- END: Social media -->

        <!-- Copyright -->

        <p class="copyright">© <span class="currentYear">2024</span> Menther, LLC</p>

      </div>

    <!-- END: Copyright & Social Media -->

      </div> 

    `
  }
}

// *********************** Define 'extends HTMLElements' Above *********************** //

customElements.define('first-nav', FirstNav);
customElements.define('footer-nav', Footer);

// *********************** Automatically Update Copyright Year *********************** //

const yearSpan = document.querySelector(".currentYear");
const currentYear = new Date();

// *********************** Highlight Active Links on Top Navigation *********************** //

document.querySelectorAll(".nav-link").forEach((link) => {
    if (link.href === window.location.href) {
        link.classList.add("active");
        link.setAttribute("aria-current", "page");
    }
});

// *********************************** BEGIN: User Auth Functions As Page Loads ********************************* //

// ********* Toogle Top Right Navigation Buttons to Hide/Show Top Nav Elements ************ //

const toggleNavBar = () => {

  if (currentUser) {
    document.querySelector('#signup-nav-button').classList.add('hidden')
    document.querySelector('#login-nav-button').classList.add('hidden')

  } else {
    document.querySelector('#signup-nav-button').classList.add('hidden')
    document.querySelector('#login-nav-button').classList.remove('hidden')
  }

};

// ********* If User Is Authorized (i.e., Logged In) => Toggle Top Right Navigation Buttons ************ //

async function getCurrentUserData() {

  try {
        currentUser = await getCurrentUser({bypassCache: true})

        console.log("Current User: ", currentUser);

        const cognitoID = currentUser.userId;

        const memberEmail = currentUser.signInDetails.loginId;

      // (A) VARIABLES TO PASS TO SESSION STORAGE

      var cognitoId = cognitoID
     
      var email = memberEmail;
 
      // (B) SAVE TO SESSION STORAGE

      sessionStorage.setItem("cognitoId", cognitoId);
   
      sessionStorage.setItem("email", email);
   
      toggleNavBar()

  } catch (err) {

    // console.log("Error is: ", err);

    if (err.name == "UserUnAuthenticatedException") {
      
      console.log("User is not logged in.");
    }

    if (err.message == "The user is not authenticated") {

      console.log("User is not logged in.");
    }

    if (err.message == "Auth UserPool not configured.") {
  
      console.log("User is not logged in.");
    }

      toggleNavBar()
  }
}

// ********* On Load, Call function to check User Auth ************ //

function getAuthOnLoad() {
  window.addEventListener('load', function() {
    getCurrentUserData()
  })
}

// **** Call getAuthOnLoad function to determine if user is logged in *** //

getAuthOnLoad()

// *********************************** END: User Auth Functions As Page Loads ********************************* //

